<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form size="medium" :label-width="this.env.label_width">
                        <el-form-item label="更新时间">
                            {{ info.update_time }}
                        </el-form-item>
                        <el-form-item label="约练广场">
                            <Qnupload v-model="info.square" :sum="1" :compress="false"/>
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="this.Tool.is_auth('weapphome.background.issave')" size="medium" type="primary"
                                       @click="save">保存
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        Qnupload,
    },
    data() {
        return {
            page_name: '页面背景图',
            info: {},
        }
    },
    // 创建
    created() {
    },
    // 安装
    mounted() {
        this.getinfo();
    },
    methods: {
        // 获取注册协议
        getinfo() {
            let postdata = {
                api_name: 'weapphome.background.getinfo',
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.info = json.data
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 保存注册协议
        save() {
            let postdata = {
                api_name: "weapphome.background.issave",
                token: this.Tool.get_l_cache('token'),
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.info);
            this.Tool.post_data('oss', postdata, (json) => {
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getinfo()    // 刷新数据
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
